.item-container{
    width: 50%; /* Fixed width */
    height: auto;
    margin: 0 auto; /* Horizontally centers the container */
    top: 50%;
    left: 50%;
    padding: 20px; /* Optional padding */
}
.mar{
    margin-top: 1.3rem ;
}
.item-menu {
    background-color: #fff4f4;
    /* border: solid 2px #D5D4DB; */
    border-radius: 10px;
    transition: 5ms;
    padding-top: 27px;
    padding-left: 8px;
    padding-bottom: 27px;
    padding-right: 8px;
    height: 100%;
}
.item-menu:hover {
    background-color: #fdeced;
    /* border: solid 2px #f37a17; */
    border-radius: 10px;
    cursor: pointer;
    transition: 5ms;
    transform: scale(1.05); 
}
.item-menu .image{
    height: 96px;
    width: 96px;
}
.item-menu h3, h4{
    color: #F2654E;
    margin-top: 16px;
}
@media (max-width: 575.98px) {
    .item-container{
        width: 95%; /* Fixed width */
        height: auto;
        margin: 0 auto; /* Horizontally centers the container */
        top: 50%;
        left: 50%;
        padding: 20px; /* Optional padding */
    }
    .mar{
        margin-top: 0.3rem ;
    }
    .custom-col {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .item-menu {
        /* margin-top: 0rem; */
         margin-left: -10px;
        margin-bottom: 0px; 
        margin-right: -10px;
        padding-top: 10px;
        padding-left: 0px;
        padding-bottom: 10px;
        padding-right: 0px;
        height: 100%;
        border-radius: 10px;
    }
    .item-menu .image {
        height: 40px; /* ลดความสูงของภาพเป็น 64px */
        width: 40px; /* ลดความกว้างของภาพเป็น 64px */
    }
    .item-menu h3, .item-menu h4 {
        font-size: 12px; /* ลดขนาดตัวอักษรเป็น 14px */
        margin-top: 8px; /* ลด margin-top เป็น 8px */
    }
}