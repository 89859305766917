.item-container{
  width: 50%; /* Fixed width */
  height: auto;
  margin: 0 auto; /* Horizontally centers the container */
  top: 50%;
  left: 50%;
  padding: 20px; /* Optional padding */
}
.mar{
  margin-top: 1.3rem ;
}
.card-menu-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* กำหนดให้มี 3 คอลัมน์เสมอ */
    gap: 10px; /* ระยะห่างระหว่าง card */
    padding: 20px;
  }
  
  .card-menu {
    background-color: #fff4f4;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .card-menu img.icon {
    width: 50px;
    height: 50px;
  }
  
  .card-menu p {
    font-size: 1rem;
    margin-top: 10px;
    color: #F2654E;
  }
  
  .card-menu:hover {
    transform: scale(1.05); 
    background-color: #fdeced;
  }
  
  /* ปรับขนาด card ในหน้าจอขนาดเล็ก */
  @media (max-width: 768px) {
    .item-container{
      width: 95%; /* Fixed width */
      height: auto;
      margin: 0 auto; /* Horizontally centers the container */
      top: 50%;
      left: 50%;
      padding: 20px; /* Optional padding */
    }
    .mar{
      margin-top: 0.3rem ;
    }
    .card-menu-grid {
      gap: 8px; /* ลดระยะห่างในหน้าจอขนาดเล็ก */
    }
  
    .card-menu {
      padding: 15px; /* ลด padding ใน card */
    }
  
    .card-menu img.icon {
      width: 35px; /* ลดขนาดไอคอน */
      height: 35px;
    }
  
    .card-menu p {
      font-size: 0.6rem; /* ลดขนาดข้อความ */
    }
  }
  
  /* ปรับขนาด card ในหน้าจอขนาดใหญ่ */
  @media (min-width: 1200px) {
    .card-menu-grid {
      gap: 15px; /* เพิ่มระยะห่างในหน้าจอขนาดใหญ่ */
    }
  
    .card-menu {
      padding: 20px; /* เพิ่ม padding ใน card */
    }
  
    .card-menu img.icon {
      width: 60px; /* เพิ่มขนาดไอคอน */
      height: 60px;
    }
  
    .card-menu p {
      font-size: 1.1rem; /* เพิ่มขนาดข้อความ */
    }
  }
  