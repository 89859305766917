.font-label {
  font-family: "Prompt-Thin";
}

.fontSizeCard-Change-pay {
  font-size: 15px;
}

.img-service-payment-change-pay {
  width: 40px;
  height: 40px;
  /* top: 236px;
  left: 101px;
  gap: 0px;
  opacity: 0px; */
}

.img-service-payment-change-pay-promtpay {
  width: 45px;
  height: 45px;
  /* top: 236px;
  left: 101px;
  gap: 0px;
  opacity: 0px; */
}


.card-change-pay {
  margin-bottom: 30px !important;
  border: none !important;
  border-radius: 5px !important;
  background-color: white;
  border: 1px #eaeaea solid !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.big-frame-change-pay {
  border: solid 2px #fdcfc7 !important;
  border-radius: 16px !important;
}

.card-service-change-pay {
  box-shadow: 0px 2px 1px 0px rgba(187, 186, 185, 0.25);
  background: rgba(255, 228, 224, 1.0);
  border-radius: 16px;
  width: 35%;
}

.card-service-change-pay:hover {
  background: rgba(255, 94, 67, 0.3);
  cursor: pointer;
}
.btn-sub {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(255, 94, 67, 1);
  border-radius: 16px;
  border: 0;
  color: white;
  width: 100%;
  margin-top: 10px;
}
.containers {
  padding: 16px;
  max-width: 1067px;
  height: auto !important;
  margin: auto !important;
}
.btn-sub-back {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 100%;
  margin-top: 10px;
}