.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body,
html,
li {
  font-family: "Prompt-Thin" !important;
  max-width: 100%;
  overflow-x: hidden;
}
.Toastify__toast-body {
  font-family: "Prompt-Thin";
}

.nav-h {
  height: 30;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cust-btn {
  background-color: #f2654e !important;
  border: #f2654e !important;
  padding: 16px 32px !important;
  color: white !important;
}

.cust-btn-back {
  background-color: rgba(242, 242, 242, 1) !important;
  border: #f2654e !important;
  padding: 16px 32px !important;
  color: black !important;
}

.width-100 {
  width: 100% !important;
}

.cust-btn:hover {
  background-color: #f9aca0 !important;
  border: #f2654e !important;
  padding: 16px 32px !important;
  color: white !important;
}

h1 {
  font-size: 40px;
}

h3 {
  font-size: 24px;
}
h2 {
  font-size: 10px;
}
h6 {
  font-size: 34px;
  font-weight: bold;
}

.txt-sm {
  color: #f2654e !important;
}

.sec-card .container-fluid {
  margin-left: 160px !important;
  margin-right: 160px !important;
}

/* @media (min-width: 1025px) {
  .container-margin {
    margin-left: 4000px !important;
    margin-right: 4000px !important;
  }
} */
@media (max-width: 575px) {
  .text-right {
    text-align: left !important;
  }
}

@media (min-width: 750px) {
}
/* Section Navigation Bar */
.nav-item {
  padding: 0 0px;
  /* padding: 0 15px; */
}

.sec-form {
  height: 100vh;
}
.bx {
  background-color: #faefe1;
  border: solid 2px #f6ddc8;
  border-radius: 16px;
  transition: 5ms;
}

.bx:hover {
  background-color: #f6ddc8;
  border: solid 2px #f6ddc8;
  border-radius: 16px;
  cursor: pointer;
  transition: 5ms;
}

.bx .image {
  height: 96px;
  width: 96px;
  /* height: 110px;
  width: 110px; */
}

.bx h3,
h4 {
  color: #f2654e;
  margin-top: 16px;
}

a {
  text-decoration: none !important;
}

.bx .flex-shrink-1:hover {
  text-decoration: none;
}

/* @media (max-width: 992px) {
  .container-fluid {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (max-width: 575px) {
  .container-fluid {
    margin-left: 8px;
    margin-right: 8px;
  }
} */

.btn-block {
  width: 100% !important;
}

.card {
  margin-bottom: 30px !important;
  border: none !important;
  border-radius: 5px !important;
  /*box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
	*/
  border: 1px #eaeaea solid !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.list-group-item {
  border: none;
}

.bold {
  font-weight: 700;
}

.light {
  color: #606060;
  margin-left: 15px;
}

.card-body {
  padding: 20px;
}

.card-body1 {
  padding: 0px;
}

.subtitle {
  font-size: 1rem;
  font-weight: 600;
}

.card-body1 {
  padding: 20px 20px 20px 20px;
  border: 1px solid #eaeaea;
  margin-top: 32px;
  border-radius: px;
}

.card-body1 span {
  font-size: 14px;
  font-weight: 400;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #ffecdf;
  color: #ff771d;
}

.dashboard .card-icon1 {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #e0f8e9;
  color: #2eca6a;
}

/* .card-body {
	padding: 16px;
	border: 1px solid #eaeaea;
} */

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.img-fluid {
  margin-top: 10%;
  max-width: 70% !important;
  height: auto !important;
}

.img-line {
  padding: 5px;
  width: 40px;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  /* border-bottom: 0px solid #eaeaea; */
}
.nav-tabs-bordered .nav-link {
  /* margin-bottom: -2px; */
  /* border: none; */
  color: #2c384e;
}
.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #f2654e;
}
.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #f2654e;
  /* border-bottom: 2px solid #F2654E; */
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(0));
}

.breadcrumb {
  background-color: #fff;
  padding: 0px;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 16px;
}
.profile .profile-overview .card-title {
  color: #003145;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 800;
}
.profile .profile-overview .label {
  font-weight: 600;
  color: #859ca6;
}
.profile .profile-edit label {
  font-weight: 600;
  color: #859ca6;
}
.profile .profile-edit img {
  max-width: 120px;
}

.table {
  min-width: max-content;
}
.table-wrapper {
  overflow-x: scroll;
}

.table th {
  font-size: 0.8rem;
  border-top: 0px;
}

.table td {
  font-size: 1rem;
  border-top: 0px;
}

.edit {
  color: #f2654e;
}

.delete {
  color: #dc3545;
  margin-left: 16px;
}

#custom-button {
  padding: 12px;
  color: white;
  background-color: #f2654e;
  border: 0px solid #000;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

#custom-button:hover {
  background-color: #7aaae3;
}

#custom-button-delete {
  padding: 11px 16px;
  color: white;
  background-color: #dc3545;
  border: 0px solid #000;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

#custom-button-delete:hover {
  background-color: #e26774;
}

.btn-primary {
  background-color: #f2654e;
  color: white;
  /* font-family: "montserrat", sans-serif; */
}

.btn-back-regis {
  background-color: rgba(242, 242, 242, 1);
  color: white;
  /* font-family: "montserrat", sans-serif; */
}

.nav-link {
  color: #f2654e;
}

.btn-blue {
  background-color: #f2654e !important;
  color: white !important;
  /* font-family: "montserrat", sans-serif!important; */
  font-size: 1rem !important;
  padding: 6px 16px !important;
}

.btn-reset {
  /* background-color: #438BE3; */
  color: #f2654e !important;
  /* font-family: "montserrat", sans-serif !important; */
  border: 2px #f2654e solid !important;
  margin-left: 16px !important;
  font-size: 1rem !important;
  padding: 6px 16px !important;
}

.btn-warning {
  position: relative;
  padding: 11px 16px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 3px;
  color: #fff;
  background-color: #f2654e;
  border: 0;
  transition: 0.2s;
  overflow: hidden;
}

.btn-warning:hover {
  background-color: #ff8b7a;
  color: #fff;
}

.btn-warning input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}

.margin-3 {
  margin: 3rem;
}
.margin-1 {
  margin: 1rem;
}
.margin-15 {
  margin: 1rem;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.error {
  font-size: small !important;
  color: red !important;
}

.header-card {
  background-color: #ffecdf !important;
  color: #ff771d !important;
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
}
.btn-outline-rabbit {
  color: #ff5e43 !important;
  border-color: #ff5e43 !important;
}

.btn-outline-rabbit:hover {
  color: #fff !important;
  background-color: #ff5e43 !important;
  border-color: #ff5e43 !important;
}

.btn-outline-rabbit.disabled,
.btn-outline-rabbit:disabled {
  color: #ff5e43 !important;
  background-color: transparent !important;
}

.btn-outline-rabbit:not(:disabled):not(.disabled).active,
.btn-outline-rabbit:not(:disabled):not(.disabled):active,
.show > .btn-outline-rabbit.dropdown-toggle {
  color: #fff !important;
  background-color: #ff5e43 !important;
  border-color: #ff5e43 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black !important;
  padding: 12px 16px !important;
  text-decoration: none !important;
  display: block !important;
}

.dropdown-content-mobile {
  display: none;
  position: absolute;
  /* right: 0px; */
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-mobile a {
  color: black !important;
  padding: 12px 16px !important;
  text-decoration: none !important;
  display: block !important;
}

.dropdown-mobile:hover .dropdown-content-mobile {
  display: block !important;
}

.info {
  width: 200px;
  margin-left: auto;
}

.mar-mi-20 {
  padding: 18px;
}
.dropdown-content a:hover {
  background-color: #ddd !important;
}

.dropdown:hover .dropdown-content {
  display: block !important;
}

.info-profile {
  /* padding: 18px 0px; */
  font-size: 16px;
  text-decoration: none;
  margin-left: auto;
  background-color: transparent;
  border: none;
  /* width: 250px; */
  width: auto;
  color: #282c34;
}

.info-profile-mobile {
  /* padding: 18px 0px; */
  font-size: 16px;
  /* text-decoration: none; */
  /* margin-left: auto; */
  background-color: transparent;
  border: none;
  /* width: 250px; */
  color: #282c34;
}

.padding-15 {
  padding: 15px;
}

.margin-auto {
  margin: auto !important;
}

.pagination {
  margin-top: 15px;
  margin-left: auto;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}
.pagination > .active > a {
  background-color: #ff5e43;
  border-color: #ff5e43;
  color: #fff;
  border-radius: 25px;
  margin: 2px;
}
.pagination > li > a {
  border: 1px solid #ff5e43;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  border-radius: 25px;
  margin: 2px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #ff5e43;
  border-color: #ff5e43;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #ff5e43;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 25px;
  margin: 2px;
}

.text-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.none-dis {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.color-text-rbl {
  color: #ff5e43;
}

.underline {
  text-decoration: underline;
}

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.md {
  position: fixed !important;
  z-index: 1 !important;
  width: 100% !important;
  height: 100% !important;
  /* background-color:red !important; */
  background-color: rgba(0, 0, 0, 0.25);
}

.md_content {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
}

.md_content_alert {
  background-color: white;
  position: absolute;
  top: 30%;
  left: 25%;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
}

.md_content_alert_pop {
  background-color: white;
  position: absolute;
  top: 15%;
  left: 25%;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
}

.scroll-y {
  overflow-y: scroll !important;
  max-height: 80% !important;
  position: absolute;
  margin-top: 30px !important;
}

.h70 {
  height: 70%;
}

.margin-l-auto {
  margin-left: auto;
}

.btn-export {
  background-color: #107c41 !important;
  color: #fff !important;
}

.disabled-tag {
  pointer-events: none;
  cursor: default;
  background-color: hsl(146, 24%, 54%) !important;
  color: #ffffffb8 !important;
}

.link-event {
  cursor: pointer;
  color: #ff5e43 !important;
}

.btn-new {
  pointer-events: none;
  cursor: default;
  padding: 3px 10px !important;
  margin: 4px !important;
  color: #fff !important;
  background-color: #f90b28 !important;
  /* border: 2px solid #F90B28 !important; */
}

.btn-taken {
  pointer-events: none;
  cursor: default;
  padding: 3px 10px !important;
  margin: 4px !important;
  color: #fff !important;
  background-color: #ff5733 !important;
}

.btn-closetype {
  pointer-events: none;
  cursor: default;
  padding: 3px 10px !important;
  margin: 4px !important;
  color: #fff !important;
  background-color: #07bd6a !important;
}
.select-input::before {
  color: gray;
}

.select-input:not(.Mui-focused)::before {
  color: black;
}
.big-frame {
  border: solid 2px #e1dfdf !important;
  border-radius: 16px !important;
}

.container {
  padding: 16px;
  max-width: 600px !important;
  margin: auto;
}

/* .img-motion-left {
  z-index: -1 !important;
  position: fixed !important;
  width: 25px !important;
  height: 25px !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.img-motion-right {
  z-index: -1 !important;
  position: fixed !important;
  width: 25px !important;
  height: 25px !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
} */

.img-motion-left {
  position: fixed !important;
  z-index: -10 !important;
  top: 1 !important;
  bottom: 50px !important;
  left: 0 !important;
  right: 0 !important;
  max-width: 50%;
  height: auto;
  display: block;
}

.img-motion-right {
  position: fixed !important;
  z-index: -1 !important;
  top: 1 !important;
  bottom: 35px !important;
  left: 1 !important;
  right: 0 !important;
  max-width: 100%;
  height: auto;
  display: block;
}

.size-img-right {
  width: 100%;
  height: auto;
}
.size-img2 {
  max-width: 100%; /* ปรับให้รูปภาพไม่เกินขนาดที่กำหนด */
  height: auto; /* รักษาสัดส่วนของรูปภาพ */
}
.size-img3 {
  max-width: 25%; /* ปรับให้รูปภาพไม่เกินขนาดที่กำหนด */
  height: auto; /* รักษาสัดส่วนของรูปภาพ */
}
.img-motion-left3,
.img-motion-right3 {
  position: absolute; /* ใช้ absolute เพื่อให้ตำแหน่งปรับได้ */
  /* top: 50%; ตั้งอยู่กลางหน้าจอในแนวตั้ง */
  transform: translateY(-50%); /* ปรับให้กลางตามแนวแกน Y */
  z-index: -1000; /* เพื่อให้รูปภาพอยู่เหนือเนื้อหาอื่น */
}
.img-motion-left2,
.img-motion-right2 {
  position: absolute; /* ใช้ absolute เพื่อให้ตำแหน่งปรับได้ */
  top: 50%; /* ตั้งอยู่กลางหน้าจอในแนวตั้ง */
  transform: translateY(-50%); /* ปรับให้กลางตามแนวแกน Y */
  z-index: -1000; /* เพื่อให้รูปภาพอยู่เหนือเนื้อหาอื่น */
}

.img-motion-left2 {
  left: 0; /* ด้านซ้าย */
}

.img-motion-right2 {
  right: 0; /* ด้านขวา */
}

.size-img {
  position: fixed !important;
  z-index: -10 !important;
  top: 1 !important;
  bottom: 1px !important;
  left: 0 !important;
  right: 0 !important;
  max-width: 100%;
  height: auto;
  display: block;
}

.MuiPickersToolbar-toolbar {
  background-color: #ff5e43 !important;
}
.MuiPickersCalendarHeader-daysHeader {
  display: none !important;
}
.MuiPickersDay-isSelected {
  color: #fff !important;
  font-weight: 500 !important;
  background-color: #ff5e43 !important;
}
.MuiPickersModal .MuiButtonBase-root {
  display: none !important;
}
.MuiDialogActions-root {
  display: none !important;
}
.MuiPickersDay-day:hover {
  background-color: #ff5e43 !important;
  color: #fff !important;
}
/* .MuiBackdrop-root {
  display: none !important;
} */
.MuiInputBase-input.MuiOutlinedInput-input {
  font-family: "Prompt-Thin", sans-serif !important;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Prompt-Thin", sans-serif !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-h4 {
  font-size: 2.125rem;
  font-family: "Prompt-Thin", sans-serif !important;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}
.MuiIconButton-root {
  padding: 1px !important;
  margin-bottom: 5px !important;
}
.MuiTableCell-root {
  font-family: "Prompt-Thin", sans-serif !important;
}

.text-orange {
  color: #ff5e43;
}
.text-long-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.btn-fill-gray {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  min-width: 80px;
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-top: 5px !important;
  margin-right: 0px !important;
}



/* Large devices (laptops/desktops, 992px and up) */
/* @media (min-width: 1) {
  .img-motion-left {
    position: fixed !important;
    z-index: -1 !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  .img-motion-right {
    position: fixed !important;
    z-index: -1 !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
  .size-img {
    width: 500px !important;
    height: 500px !important;
  }
} */
