.card-frame {
  background-color: #ffffff;
  border: solid 1px #a39f9d;
  border-radius: 16px;
  transition: 5ms;
  height: 100%;
}
.frame-policy {
  border: solid 2px #fdcfc7 !important;
  border-radius: 16px !important;
}
.frame-policy:hover {
  background-color: #f6ddc8;
  border: solid 2px #f37a17;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.frame-other {
  overflow-y: hidden;
  max-height: 360px;
}
.frame-other2 {
  overflow-y: scroll;
  max-height: 345px;
}
.frame-selected {
  background-color: #28168d;
  border: solid 2px #85ce0f;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.body-list-group {
  padding: 5px;
}
.pay-btn {
  background-color: #ff5e43 !important;
  border-radius: 16px !important;
  border: #ff5e43 !important;
  padding: 10px 25px !important;
  color: white !important;
  /* width: 50% !important; */
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.pay-btn:hover {
  background-color: #d8452e !important;
  border: #ff5e43 !important;
  padding: 10px 25px !important;
  color: white !important;
}
.btn-back {
  background-color: #f2f2f2 !important;
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5) !important;
  border-radius: 16px !important;
  padding: 10px 25px !important;
  color: #2f2d2c !important;
  /* width: 50% !important; */
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.btn-back:hover {
  background-color: #a09e9e !important;
  border: #bbbab9 !important;
  padding: 10px 25px !important;
  color: #000000 !important;
}
.custom-tab {
  background-color: #a09e9e !important;
  /* border: #BBBAB9 !important; */
  color: #720000 !important;
  padding: 0 5px;
}
.nav-link.active {
  background-color: #ff5e43 !important;
  color: white !important;
  border-radius: 0px !important;
  overflow-y: hidden !important;
  max-width: 100% !important;
}

.nav-link {
  color: black !important;
  border-radius: 0px !important;
  overflow-y: hidden !important;
  max-width: 100% !important;
}

@media (max-width: 575.98px) {
  .custom-col {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .item-menu {
    /* margin-top: 0rem; */
    margin-left: -10px;
    margin-bottom: 0px;
    margin-right: -10px;
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 10px;
    padding-right: 0px;
    height: 100%;
  }
  .item-menu .image {
    height: 40px; /* ลดความสูงของภาพเป็น 64px */
    width: 40px; /* ลดความกว้างของภาพเป็น 64px */
  }
  .item-menu h3,
  .item-menu h4 {
    font-size: 12px; /* ลดขนาดตัวอักษรเป็น 14px */
    margin-top: 8px; /* ลด margin-top เป็น 8px */
  }
}
@media (min-width: 1000px) {
  .pay-btn,
  .btn-back {
    width: 30% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
  }
  .margin-15 {
    margin-top: 2rem;
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .btn-checkout-back {
    box-shadow: 0px 2px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: rgba(242, 242, 242, 1);
    border-radius: 16px;
    border: 0;
    color: #000;
    width: 25%;
  }
  #fill-tab-example {
    margin-bottom: 0px !important;
  }
  #fill-tab-example-tab-home {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  #fill-tab-example-tab-profile {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.frame-card {
  border: solid 2px #fdcfc7 !important;
  border-radius: 0 0 16px 16px !important; /* มุมทุกด้านโค้ง */
}
.frame-card2 {
  border: solid 2px #fdcfc7 !important;
  border-radius: 0 0 16px 16px !important; /* มุมด้านล่างเท่านั้นที่โค้ง */
}
.card-custom {
  border: solid 2px #fdcfc7 !important;
  background-color: #ffff !important;
  border-radius: 0 0 16px 16px !important;
}
.card-custom2 {
  background-color: rgba(246, 246, 246, 1);
  border: solid 2px rgba(246, 246, 246, 1) !important;
  border-radius: 16px !important;
  height: 230px;
}
.card-custom3 {
  background-color: rgba(246, 246, 246, 1);
  border: solid 2px rgba(246, 246, 246, 1) !important;
  border-radius: 16px !important;
  height: 280px;
}
@media (min-width: 360px) {
  .small-font {
    font-size: 8px;
  }
}
.pay-btn,
.btn-back {
  width: 30% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
}
.margin-15 {
  margin-top: 2rem;
  margin-right: 10rem;
  margin-left: 10rem;
}
#fill-tab-example {
  margin-bottom: 0px !important;
}
#fill-tab-example-tab-home {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#fill-tab-example-tab-profile {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.btn-checkout-back {
  box-shadow: 0px 2px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  color: #000;
  width: 25%;
}
.customDots:focus {
  border-radius: 25px;
  border: 0;
  background: rgba(255, 94, 67, 0.3) !important;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.customDots {
  border-radius: 25px;
  border: 0;
  background: rgba(68, 46, 46, 0.11);
  width: 15px;
  height: 15px;
  margin-left: 5px;
  z-index: 10;
}
.link {
    color: inherit;
    text-decoration: underline !important;
    display: inline-block;
    font-size: 15px;
}
.carousel .control-dots .dot:active {
  border-radius: 25px;
  border: 0;
  background: rgba(255, 94, 67, 0.3) !important;
  width: 15px;
  height: 15px;
  /* margin-left: 5px; */
}

.font-size-labels {
  font-size: 14px;
  color: #333;
  display: inline-block;
  margin-left: 15px;
}

.font-size-labels-det {
  font-size: 14px;
  color: #333;
  display: inline-block;
}

.bold-detail {
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
}