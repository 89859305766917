.card-frame {
  background-color: #ffffff;
  border: solid 1px #a39f9d;
  border-radius: 16px;
  transition: 5ms;
  height: 100%;
}
.frame-policy {
  border: solid 2px #fdcfc7 !important;
  border-radius: 16px !important;
}
.frame-policy:hover {
  background-color: #f6ddc8;
  border: solid 2px #f37a17;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.frame-other {
  border: solid 2px #fdcfc7 !important;
  border-radius: 16px !important;
}
.frame-card {
  border: solid 2px #fdcfc7 !important;
  border-radius: 0 0 16px 16px !important; /* มุมด้านล่างเท่านั้นที่โค้ง */
}
.frame-selected {
  background-color: #28168d;
  border: solid 2px #85ce0f;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.body-list-group {
  padding: 5px;
}
.download-reciept {
  color: rgba(255, 94, 67, 1);
  text-decoration: underline;
}

.pay-btn {
  background-color: #ff5e43 !important;
  border-radius: 16px !important;
  border: #ff5e43 !important;
  padding: 10px 25px !important;
  color: white !important;
  /* width: 50% !important; */
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.pay-btn:hover {
  background-color: #d8452e !important;
  border: #ff5e43 !important;
  padding: 10px 25px !important;
  color: white !important;
}
.btn-back {
  background-color: #f2f2f2 !important;
  border-radius: 16px !important;
  padding: 10px 25px !important;
  color: #2f2d2c !important;
  /* width: 50% !important; */
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.btn-back:hover {
  background-color: #a09e9e !important;
  border: #bbbab9 !important;
  padding: 10px 25px !important;
  color: #000000 !important;
}
.custom-field {
  border-radius: 16px !important;
  font-size: 20px;
  width: 100%;
  border: solid 2px #8d8d8d70 !important;
}
.custom-tab {
  background-color: #a09e9e !important;
  /* border: #BBBAB9 !important; */
  color: #720000 !important;
  padding: 0 5px;
}
.nav-link.active {
  background-color: #ff5e43 !important;
  color: white !important;
  border-radius: 8px !important;
}

.nav-link {
  background-color: #e7e7e786;
  color: black !important;
  border-radius: 8px !important;
}

@media (max-width: 575.98px) {
  .row-custom {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #f4c6be;
    border-left: 1px solid #f4c6be;
    border-right: 1px solid #f4c6be;
    border-radius: 0 0 5px 5px; /* โค้งเฉพาะมุมล่าง */

    padding: 10px; /* เพิ่ม padding ถ้าต้องการ */
    box-shadow: 0 1px 2px #efc4bd;
  }
  .custom-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .item-menu {
    /* margin-top: 0rem; */
    margin-left: -10px;
    margin-bottom: 0px;
    margin-right: -10px;
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 10px;
    padding-right: 0px;
    height: 100%;
  }
  .item-menu .image {
    height: 40px; /* ลดความสูงของภาพเป็น 64px */
    width: 40px; /* ลดความกว้างของภาพเป็น 64px */
  }
  .item-menu h3,
  .item-menu h4 {
    font-size: 12px; /* ลดขนาดตัวอักษรเป็น 14px */
    margin-top: 8px; /* ลด margin-top เป็น 8px */
  }
  ul {
    padding-right: 0px;
  }
}
@media (min-width: 1000px) {
  .row-custom {
    border-bottom: 1px solid #f4c6be;
    border-left: 1px solid #f4c6be;
    border-right: 1px solid #f4c6be;
    border-radius: 0 0 5px 5px; /* โค้งเฉพาะมุมล่าง */

    padding: 10px; /* เพิ่ม padding ถ้าต้องการ */
    box-shadow: 0 1px 2px #efc4bd;
  }
  .pay-btn,
  .btn-back {
    width: 30% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
  }
  .margin-15 {
    margin-top: 2rem;
    margin-right: 10rem;
    margin-left: 10rem;
  }
  ul {
    padding-right: 0px !important;
  }
  #fill-tab-example {
    margin-bottom: 0px !important;
  }
  #fill-tab-example-tab-home {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  #fill-tab-example-tab-profile {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}



.paper {
  padding: 16px!important;
  margin-bottom: 16px!important;
  border: 1px solid rgba(248, 135, 115, 0.5)!important; /* สีขอบโปร่งใส 50% */
  box-shadow: 0 4px 8px rgba(255, 94, 67, 0.3)!important;
  border-radius: 12px!important;
}

.button-container {
  text-align: center!important;
  margin-top: 20px!important;
}

.button {
  background-color: #FF6F61!important;
}

.button:hover {
  background-color: #e65b50!important;
}

.label {
  font-weight: bold!important;
}

.value {
  text-align: right!important;
}


.button {
  background-color: #E0E0E0!important; /* สีพื้นหลังของปุ่ม */
  color: black!important; /* สีของข้อความ */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)!important; /* เงาของปุ่ม */
  border-radius: 8px!important; /* ขอบโค้งของปุ่ม */
  padding: 8px 16px!important;
  width: 300px;
}

.button:hover {
  background-color: #D5D5D5!important; /* สีพื้นหลังของปุ่มเมื่อ hover */
}

.value{
  font-weight: 300;
}
.btn-checkout-back {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 100%;
  margin-top: 25px;
}