.card-frame {
  background-color: #ffffff;
  border: solid 1px #A39F9D;
  border-radius: 16px;
  transition: 5ms;
  height: 100%;

}
.frame-policy {
  border: solid 2px #fdcfc7 !important;
  border-radius: 16px !important;
}
.frame-policy:hover {
  background-color: #f6ddc8;
  border: solid 2px #f37a17;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.frame-other {
  max-height: 1067px;
  border: solid 1px #f0f0f0 !important;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  background-color: #ffffff;
}
.frame-other2 {
  overflow-y: scroll ;
  max-height: 345px;
}
.frame-selected {
  background-color: #28168d;
  border: solid 2px #85ce0f;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.body-list-group {
  padding: 5px;

}
.pay-btn{
  background-color: #FF5E43 !important;
  border-radius: 16px !important;
  border: #FF5E43 !important;
  padding: 10px 25px !important;
  color: white !important;
  /* width: 50% !important; */
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.pay-btn:hover{
  background-color: #d8452e !important;
  border: #FF5E43 !important;
  padding: 10px 25px !important;
  color: white !important;
}
.btn-back{
  background-color: #F2F2F2 !important;
  border-radius: 16px !important;
  padding: 10px 25px !important;
  color: #2F2D2C !important;
  width: 30% !important; 
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.btn-confirm{
  background-color: #FF5E43!important;
  border-radius: 16px !important;
  padding: 10px 25px !important;
  color: white !important;
  width: 30% !important; 
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.btn-back:hover{
  background-color: #a09e9e !important;
  border: #BBBAB9 !important;
  padding: 10px 25px !important;
  color: #000000 !important;
}
.custom-tab{
  background-color: #a09e9e !important;
  /* border: #BBBAB9 !important; */
  color: #720000 !important;
  padding: 0 5px;
  
}
.nav-link.active {
  background-color: #FF5E43 !important;
  color: white !important;
  border-radius: 8px !important;

}

.nav-link {
  color: black !important;
  border-radius: 8px !important;

}

@media (max-width: 575.98px) {
  .custom-col {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .item-menu {
      /* margin-top: 0rem; */
      margin-left: -10px;
      margin-bottom: 0px; 
      margin-right: -10px;
      padding-top: 10px;
      padding-left: 0px;
      padding-bottom: 10px;
      padding-right: 0px;
      height: 100%;
  }
  .item-menu .image {
      height: 40px; /* ลดความสูงของภาพเป็น 64px */
      width: 40px; /* ลดความกว้างของภาพเป็น 64px */
  }
  .item-menu h3, .item-menu h4 {
      font-size: 12px; /* ลดขนาดตัวอักษรเป็น 14px */
      margin-top: 8px; /* ลด margin-top เป็น 8px */
  }
  .btn-back{
    background-color: #F2F2F2 !important;
    border-radius: 16px !important;
    padding: 10px 25px !important;
    color: #2F2D2C !important;
    width: 30% !important; 
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
  }
  .btn-confirm{
    background-color: #FF5E43!important;
    border-radius: 16px !important;
    padding: 10px 25px !important;
    color: white !important;
    width: 30% !important; 
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
  }
  .d-flex.justify-content-center.flex-wrap {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 1000px) {
  .pay-btn .btn-back {
      width: 30% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
  }
  .margin-15 {
      margin-top: 2rem;
      margin-right: 10rem;
      margin-left: 10rem;
  }
}
/* .body-layout-head {
  display: flex;
  margin-left: 600px !important;
}
.body-layout-chkbox {
  display: flex;
  margin-left: 120px !important;
}
.body-layout-text {
  display: flex;
  margin-left: 105px !important;
} */
.text-desc {
   margin-left: 1px;
   margin-right: 15px;
   font-size: 17px;
   color: #808080;
}
.value-item {
  margin-left: 30px;
  margin-top: 11px;
  font-weight: bold;
}
.md {
  position: fixed !important;
  z-index: 1 !important;
  width: 100% !important;
  height: 100% !important;
  /* background-color:red !important; */
  background-color: rgba(0, 0, 0, 0.25);
}
.md_content {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
}

.btn-ex-back {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 30%;
  margin-top: 25px;
}
.btn-address-next {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: #ff5e43;
  border-radius: 16px;
  border: 0;
  color: white;
  width: 100%;
}

.btn-address-back {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 100%;
}
.action-address {
  /* margin-top: 24px;
  align-content: center; */
  /* display: flex; */
  align-items: center;   /* จัดตำแหน่งตรงกลางแนวตั้ง */
  justify-content: center; /* จัดตำแหน่งตรงกลางแนวนอน */
  /* min-height: 100vh;  */
}


@media (min-width: 234px) {
  .body-layout-head {
    display: flex;
    margin-left: 5px !important;
  }
  .body-layout-text {
    display: flex;
    margin-left: 35px !important;
  }
  .body-layout-chkbox {
    display: flex;
    margin-left: 45px !important;
  }
}

