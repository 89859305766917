.btn-upload-image {
  cursor: pointer;
  padding: 8px;
  min-height: 35px;
  min-width: 96px;
  max-width: 200px;
  overflow-wrap: anywhere;
  gap: 2px;
  border-radius: 10px 10px 10px 10px;
  border: 0.2px 0px 0px 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0.2px solid rgba(140, 136, 136, 1);
  background: rgba(246, 246, 246, 1);
}

.btn-upload-image.btn-disabled {
  cursor: not-allowed;
  box-shadow: none;
  color: rgba(140, 136, 136, 1);
  border: none;
}

.btn-upload-image.btn-error {
  border: 0.2px solid red;
}

.icon-image-content-upload {
  width: 15px;
  height: 15px;
}
