.card-frame {
    background-color: #ffffff;
    border: solid 1px #A39F9D;
    border-radius: 16px;
    transition: 5ms;
    height: 100%;
  
}
.frame-policy {
    border: solid 2px #fdcfc7 !important;
    border-radius: 16px !important;
}
.frame-policy:hover {
    background-color: #f6ddc8;
    border: solid 2px #f37a17;
    outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.frame-other {
    max-height: 100%;
    border: solid 1px #f0f0f0 !important;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    background-color: #ffffff !important;
}
.frame-selected {
    background-color: #28168d;
    border: solid 2px #85ce0f;
    outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.body-list-group {
    padding: 5px;

}
.pay-btn{
    background-color: #FF5E43 !important;
    border-radius: 16px !important;
    border: #FF5E43 !important;
    padding: 10px 25px !important;
    color: white !important;
    /* width: 50% !important; */
    font-size: 20px;
    font-weight: bold;
}
.pay-btn:hover{
    background-color: #d8452e !important;
    border: #FF5E43 !important;
    padding: 10px 25px !important;
    color: white !important;
}
.btn-back{
    background-color: #F2F2F2 !important;
    border-radius: 16px !important;
    padding: 10px 25px !important;
    color: #2F2D2C !important;
    /* width: 50% !important; */
    font-size: 20px;
    font-weight: bold;
}
.btn-back:hover{
    background-color: #a09e9e !important;
    border: #BBBAB9 !important;
    padding: 10px 25px !important;
    color: #000000 !important;
}

@media (max-width: 575.98px) {
    .custom-col {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .item-menu {
        /* margin-top: 0rem; */
        margin-left: -10px;
        margin-bottom: 0px; 
        margin-right: -10px;
        padding-top: 10px;
        padding-left: 0px;
        padding-bottom: 10px;
        padding-right: 0px;
        height: 100%;
    }
    .item-menu .image {
        height: 40px; /* ลดความสูงของภาพเป็น 64px */
        width: 40px; /* ลดความกว้างของภาพเป็น 64px */
    }
    .item-menu h3, .item-menu h4 {
        font-size: 12px; /* ลดขนาดตัวอักษรเป็น 14px */
        margin-top: 8px; /* ลด margin-top เป็น 8px */
    }
}
@media (min-width: 576px) {
    .pay-btn, .btn-back {
        width: 20% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
    }
    .margin-1 {
        margin-top: 2rem;
        margin-right: 10rem;
        margin-left: 10rem;
      }
}
.text-description {
    margin-left: 1px;
    margin-right: 15px;
    font-size: 15px;
    color: #808080;
}

.frame-doc {
    max-height: 100%;
    border: solid 1px #f0f0f0 !important;
    background-color: #ffffff !important;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 16px !important;
}
.container {
    padding: 16px;
    max-width: 600px !important;
    margin: auto;
  }
.table-page-container {
    max-width: 1067px;
    padding: 16px;
    margin: auto;
}
.rounded-border {
    border-radius: 14px;
    border: 1px solid #e1dfdfa8;
}

@media (max-width: 343.98px) {
    .value-policy {
        margin-left: -5px;
    }
}
.h-30px {
    height: 30px;
}
.loading-content {
    position: absolute; /* ป้องกันไม่ให้ทำให้ layout เปลี่ยน */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: 1000; /* ให้อยู่บนสุด */
    display: flex;
    flex-direction: column; /* ใช้ flexbox เพื่อจัดเรียง Skeleton */
    justify-content: center; /* จัดให้อยู่กลาง */
    align-items: center; /* จัดให้อยู่กลาง */
  }
