.btn-send-doc {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(255, 94, 67, 1);
  border-radius: 16px;
  border: 0;
  color: white;
  width: 100%;
  margin-top: 10px;
}
.containers {
  padding: 16px;
  max-width: 1067px;
  height: auto !important;
  margin: auto !important;
}
.btn-coupon-back {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 100%;
  margin-top: 10px;
}

.card-bg-detail {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 232, 232, 1);
  box-shadow: 0px 4px 2px 0px rgba(187, 186, 185, 0.25);
  margin-left: 8px;
  margin-right: 8px;
}

.font-size-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 17.64px;
}

.font-size-sub-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 15.12px;
  
}

.font-size-content {
  font-size: 15px;
  line-height: 15.12px;
}

.font-size-content-upload {
  font-size: 15px;
  line-height: 15.12px;
}

.icon-image-content {
  width: 20px;
  height: 20px;
}

.icon-image-content-upload {
  width: 15px;
  height: 15px;
}

.btn-upload-file {
  width: 85px;
  height: 35px;
  gap: 2px;
  border-radius: 10px 10px 10px 10px;
  border: 0.2px 0px 0px 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0.2px solid rgba(140, 136, 136, 1);
  background: rgba(246, 246, 246, 1);
}

.font-new {
  font-family: "Prompt-Thin" !important;
  font-size: 15px !important;
}

.card-service-payment {
  box-shadow: 0px 2px 1px 0px rgba(187, 186, 185, 0.25);
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  border: 1px solid rgba(216, 209, 209, 1);
  width: 35%;
}

.card-service-payment:hover {
  background: rgba(255, 94, 67, 0.3);
}

.img-service-payment {
  width: 40px;
  height: 40px;
  /* top: 236px;
  left: 101px;
  gap: 0px;
  opacity: 0px; */
}

/* หน้าจอใหญ่ */
.card-custom {
  border: solid 2px #fdcfc7 !important;
  background-color: #ffff !important;
  border-radius: 0 0 16px 16px !important;
}
.frame-custom {
  border: solid 2px #fdcfc7 !important;
  background-color: #ffff !important;
  border-radius: 16px !important;
}
.warning-word {
  color: #FF5E43;
  font-size: 14px;
}
.card-custom-loading {
  background-color: rgba(246, 246, 246, 1) !important;
  border-radius: 16px !important;
  height: 330px;
}
.text-loading {
  margin-left: -25px;
  width: 120px ;
  margin-bottom:10px;
  height : 20px;
}
.btn-checkout-premium:disabled {
  opacity: 0.5; 
  cursor: not-allowed; 
}
.containers {
  padding: 16px;
  max-width: 1067px;
  height: auto !important;
  margin: auto !important;
}