.card-frame {
    background-color: #ffffff;
    border: solid 1px #A39F9D;
    border-radius: 16px;
    transition: 5ms;
    height: 100%;
  
}
.frame-policy {
    border: solid 2px #fdcfc7 !important;
    border-radius: 16px !important;
}
.textFeild {
    background-color: #ffffff  !important;
    border: solid 1px #A39F9D  !important;
    border-radius: 16px  !important;
  
}
.frame-policy:hover {
    background-color: #f6ddc8;
    border: solid 2px #f37a17;
    outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.frame-other {
    border: solid 2px #fdcfc7 !important;
    border-radius: 16px !important;
}
.big-frame {
    border: solid 2px #E1DFDF !important;
    border-radius: 16px !important;
}
.frame-card {
    border: solid 2px #fdcfc7 !important;
    border-radius: 0 0 16px 16px !important; /* มุมด้านล่างเท่านั้นที่โค้ง */
}
.frame-selected {
    background-color: #28168d;
    border: solid 2px #85ce0f;
    outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.body-list-group {
    padding: 5px;
}
.pay-btn{
    background-color: #FF5E43 !important;
    border-radius: 16px !important;
    border: #FF5E43 !important;
    padding: 10px 25px !important;
    color: white !important;
    /* width: 50% !important; */
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
}
.pay-btn:hover{
    background-color: #d8452e !important;
    border: #FF5E43 !important;
    padding: 10px 25px !important;
    color: white !important;
}
.btn-back{
    background-color: #F2F2F2 !important;
    border-radius: 16px !important;
    padding: 10px 25px !important;
    color: #2F2D2C !important;
    /* width: 50% !important; */
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
}
.btn-back:hover{
    background-color: #a09e9e !important;
    border: #BBBAB9 !important;
    padding: 10px 25px !important;
    color: #000000 !important;
}
.btn-upload{
    background-color: #F2F2F2 !important;
    border-radius: 16px !important;
    padding: 0px 10px !important;
    color: #2F2D2C !important;
    /* width: 50% !important; */
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 20px; 
}
.btn-upload:hover{
    background-color: #b4b2b2 !important;
    border: #BBBAB9 !important;
    padding: 0px 10px !important;
    color: #000000 !important;
}
.btn-send{
    font-size: 21px;
    font-weight: bold;
}
.btn-send:hover{
    background-color: #c7c7c7 !important;
    /* border: #BBBAB9 !important; */
    /* color: #000000 !important; */
}
.download-btn{
    background-color: #ffffff !important;
    border-radius: 16px !important;
    border: 2px solid #FF5E43 !important;
    padding: 10px 25px !important;
    color: #FF5E43 !important;
    /* width: 50% !important; */
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
    box-sizing: border-box; /* ให้ padding และ border ไม่ทับกัน */
}
.download-btn:hover{
    background-color: #d8452e !important;
    border: 2px solid #FF5E43 !important;
    padding: 10px 25px !important;
    color: white !important;
}
.font-field{
    font-size: 20px;
}
.custom-field{
    border-radius: 16px !important;
    font-size: 20px;
    width: 100%;
    border: solid 2px #eeeeee70 !important;

    
}
.custom-fieldDDL{
    border-radius: 16px !important;
    font-size: 20px;
    width: 100%;
    border: solid 2px #5f5f5f70 !important;

    
}
.custom-tab{
    background-color: #a09e9e !important;
    /* border: #BBBAB9 !important; */
    color: #720000 !important;
    padding: 0 5px;
    
}
.nav-link.active {
    background-color: #FF5E43 !important;
    color: white !important;
    border-radius: 8px !important;

}

.nav-link {
    background-color: #e7e7e786;
    color: black !important;
    border-radius: 8px !important;

}
.tableCell {
    padding: '1px' !important;
}

@media (max-width: 575.98px) {
    .custom-col {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .icon-image {
        height: 35px;
        width: 35px; /* ลดความกว้างของภาพเป็น 64px */
        margin-left: 15px;
    }
    .icon-image2 {
        height: 30px;
        width: 30px; /* ลดความกว้างของภาพเป็น 64px */
        /* margin-left: 15px; */
    }
    .item-menu {
        /* margin-top: 0rem; */
        margin-left: -10px;
        margin-bottom: 0px; 
        margin-right: -10px;
        padding-top: 10px;
        padding-left: 0px;
        padding-bottom: 10px;
        padding-right: 0px;
        height: 100%;
    }
    .item-menu .image {
        height: 40px; /* ลดความสูงของภาพเป็น 64px */
        width: 40px; /* ลดความกว้างของภาพเป็น 64px */
    }
    .item-menu .image2 {
        height: 40px; /* ลดความสูงของภาพเป็น 64px */
        width: 70px; /* ลดความกว้างของภาพเป็น 64px */
    }
    .item-menu h3, .item-menu h4 {
        font-size: 12px; /* ลดขนาดตัวอักษรเป็น 14px */
        margin-top: 8px; /* ลด margin-top เป็น 8px */
    }
    .cus-frame2{
        margin-left: 10px;
        margin-right: 10px;

    }
    .download-btn {
        width:45% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
    }
    .font-field{
        font-size: 15px;
    }
    
}
@media (min-width: 1000px) {
    .pay-btn, .btn-back, .download-btn {
        width: 30% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
    }
    .margin-15 {
        margin-top: 2rem;
        margin-right: 10rem;
        margin-left: 10rem;
    }
    .icon-image {
        height: 50px;
        width: 50px; /* ลดความกว้างของภาพเป็น 64px */
        margin-left: 50px;
    }
    .item-menu .image {
        height: 96px; /* ลดความสูงของภาพเป็น 64px */
        width: 96px; /* ลดความกว้างของภาพเป็น 64px */
    }
    .item-menu .image2 {
        height: 96px; /* ลดความสูงของภาพเป็น 64px */
        width: 200px; /* ลดความกว้างของภาพเป็น 64px */
    }
    .cus-frame{
        margin-left: 50px;
    }
    .cus-frame2{
        margin-left: 50px;
        margin-right: 50px;
    }
    .step-frame{
        margin-left: 200px;
        margin-right: 200px;
    }
    .icon-image2 {
        height: 35px;
        width: 35px; /* ลดความกว้างของภาพเป็น 64px */
        /* margin-left: 15px; */
    }
}
