.middle-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80;
}

.consent-font-size {
    font-size: 15px;
    color: #606060;
}

.title-font {
    font-size: 15px;
    color: #606060;
    font-weight: bold;
}

.title-file-font {
    font-size: 15px;
    color: #fdcfc7;
}

.border-content {
    display: flex;
    flex-direction: column;
    border: 2px solid #fdcfc7;
    border-radius: 10px;
    padding: 20px;
    width: 'auto';
    height: 'auto';
    background-color: white;
}

.button-contain {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.container-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scrollable-container {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
}

.border-content-inside {
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(225, 223, 223);
    /* Grey border */
    padding-left: 20px;
    /* Optional: Adds some padding inside the box */
    padding-right: 20px;
    /* Optional: Adds some padding inside the box */
    height: 120px;
    box-shadow: 0px 4px 2px 0px rgba(187, 186, 185, 0.5);
}

.text-label {
    font-size: 15px;
    margin-left: 10px;
}

.item-between {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
}

.font-size-content {
    font-size: 15px;
    line-height: 15.12px;
}

.font-size-content-alert-modal {
    font-size: 20px;
    line-height: 21px;
}

.font-size-content-otp {
    font-size: 12px;
    line-height: 15.12px;
}

.icon-image-content-upload {
    width: 20px;
    height: 20px;
}

.render-file-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-right: 15px;
}

.btn-checkout-back-modal {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: rgba(242, 242, 242, 1);
    border-radius: 16px;
    border: 0;
    width: 50%;
    margin-top: 25px;
  }

@media (max-width: 420px) {
    .text-label {
        font-size: 12px;
        margin-left: 10px;
    }

    .item-between {
        display: flex;
        flex-direction: row;
        margin-top: 7px;
        align-items: center;
    }

    .font-size-content {
        font-size: 12px;
        line-height: 15.12px;
    }

    .consent-font-size {
        font-size: 13px;
        color: #606060;
    }

    .render-file-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    .scrollable-container {
        max-height: 170px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .font-size-content-alert-modal {
        font-size: 15px;
        line-height: 15.12px;
    }

    .title-file-font {
        font-size: 12px;
        color: #fdcfc7;
    }
}

@media (max-height: 668px) {
    .button-contain {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
    }
}