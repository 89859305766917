.table-page-container {
  max-width: 1067px;
  padding: 16px;
  margin: auto;
}
.rounded-border {
  border-radius: 14px;
  border: 1px solid #e1dfdfa8;
}
.filter-section {
  margin-top: 24px;
  padding-inline: 32px;
}
.filter-action-section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.card-frame {
  background-color: #ffffff;
  border: solid 1px #A39F9D;
  border-radius: 16px;
  transition: 5ms;
  height: 100%;

}
.frame-policy {
  border: solid 2px #fdcfc7 !important;
  border-radius: 16px !important;
}
.frame-policy:hover {
  background-color: #f6ddc8;
  border: solid 2px #f37a17;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.frame-other {
  max-height: 100%;
  border: solid 1px #f0f0f0 !important;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
}
.frame-other2 {
  overflow-y: scroll ;
  max-height: 345px;
}
.frame-selected {
  background-color: #28168d;
  border: solid 2px #85ce0f;
  outline: none; /* ลบเส้นโค้งรอบขอบเมื่อมีการ focus */
}
.body-list-group {
  padding: 5px;

}
.pay-btn{
  background-color: #FF5E43 !important;
  border-radius: 16px !important;
  border: #FF5E43 !important;
  padding: 10px 25px !important;
  color: white !important;
  /* width: 50% !important; */
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.pay-btn:hover{
  background-color: #d8452e !important;
  border: #FF5E43 !important;
  padding: 10px 25px !important;
  color: white !important;
}
.btn-back{
  background-color: #F2F2F2 !important;
  border-radius: 16px !important;
  padding: 10px 25px !important;
  color: #2F2D2C !important;
  width: 20% !important; 
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.btn-confirm{
  background-color: #FF5E43!important;
  border-radius: 16px !important;
  padding: 10px 25px !important;
  color: white !important;
  width: 10% !important; 
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}
.btn-back:hover{
  background-color: #a09e9e !important;
  border: #BBBAB9 !important;
  padding: 10px 25px !important;
  color: #000000 !important;
}
.btn-fill-gray {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  min-width: 80px;
}
.btn-disabled {
  background-color: gray;
  color: #ccc;
}
.btn-new {
  /* pointer-events: none;
  cursor: default;
  padding: 3px 10px !important;
  margin: 4px !important; */
  color: #FA0E0E !important;
  /* background-color: #F90B28 !important; */
  /* border: 2px solid #F90B28 !important; */
}

.btn-taken {
  /* pointer-events: none;
  cursor: default;
  padding: 3px 10px !important;
  margin: 4px !important; */
  color: #FF5E43 !important;
  /* background-color: #FF5733 !important; */
}

.btn-closetype {
  /* pointer-events: none;
  cursor: default;
  padding: 3px 10px !important;
  margin: 4px !important; */
  color: #12B71D !important;
  /* background-color: #07BD6A !important; */
}
.link-event {
  cursor: pointer;
  color: #008DF4 !important;
}
.checkbox-large {
  width: 20px; /* กำหนดความกว้างของ checkbox */
  height: 20px; /* กำหนดความสูงของ checkbox */
}
.loading-content {
  position: absolute; /* ป้องกันไม่ให้ทำให้ layout เปลี่ยน */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); 
  z-index: 1000; /* ให้อยู่บนสุด */
  display: flex;
  flex-direction: column; /* ใช้ flexbox เพื่อจัดเรียง Skeleton */
  justify-content: center; /* จัดให้อยู่กลาง */
  align-items: center; /* จัดให้อยู่กลาง */
}

.btn-fill-orange {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: #ff5e43;
  border-radius: 16px;
  border: 0;
  min-width: 80px;
  color: white;
}
.btn-fill-export {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: #f8914d;
  border-radius: 16px;
  border: 0;
  min-width: 80px;
  color: white;
}
.custom-tab{
  background-color: #a09e9e !important;
  /* border: #BBBAB9 !important; */
  color: #720000 !important;
  padding: 0 5px;
  
}
.nav-link.active {
  background-color: #FF5E43 !important;
  color: white !important;
  border-radius: 8px !important;

}


.nav-link {
  color: black !important;
  border-radius: 8px !important;

}

@media (max-width: 575.98px) {
  .custom-col {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .item-menu {
      /* margin-top: 0rem; */
      margin-left: -10px;
      margin-bottom: 0px; 
      margin-right: -10px;
      padding-top: 10px;
      padding-left: 0px;
      padding-bottom: 10px;
      padding-right: 0px;
      height: 100%;
  }
  .item-menu .image {
      height: 40px; /* ลดความสูงของภาพเป็น 64px */
      width: 40px; /* ลดความกว้างของภาพเป็น 64px */
  }
  .item-menu h3, .item-menu h4 {
      font-size: 12px; /* ลดขนาดตัวอักษรเป็น 14px */
      margin-top: 8px; /* ลด margin-top เป็น 8px */
  }
  .btn-back{
    background-color: #F2F2F2 !important;
    border-radius: 16px !important;
    padding: 10px 25px !important;
    color: #2F2D2C !important;
    width: 20% !important; 
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
  }
  .btn-confirm{
    background-color: #FF5E43!important;
    border-radius: 16px !important;
    padding: 10px 25px !important;
    color: white !important;
    width: 10% !important; 
    font-size: 20px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
  }
  .d-flex.justify-content-center.flex-wrap {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 1000px) {
  .pay-btn, .btn-back {
      width: 10% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
  }
  .margin-15 {
      margin-top: 2rem;
      margin-right: 10rem;
      margin-left: 10rem;
  }
}
.body-layout-chkbox-head {
  display: flex;
  margin-left: 85px;
  margin-right: 40px;
}
.body-layout-chkbox {
  display: flex;
  margin-left: 120px;
  margin-right: 30px;
}
.body-layout-text {
  display: flex;
  margin-left: 105px;
  margin-right: 30px;
}
.text-desc {
   margin-left: 1px;
   margin-right: 15px;
   font-size: 17px;
   color: #808080;
}
.value-item {
  margin-left: 30px;
  margin-top: 11px;
  font-weight: bold;
}
.md {
  position: fixed !important;
  z-index: 1 !important;
  width: 100% !important;
  height: 100% !important;
  /* background-color:red !important; */
  background-color: rgba(0, 0, 0, 0.25);
}
.md_content {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
}
.frame-other-border {
  border: solid 1px #A39F9D  !important;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}
@media (max-width: 576px) {
  .select-input {
    width: 100%;
  }
}

/* สำหรับหน้าจอขนาดกลาง */
@media (min-width: 577px) and (max-width: 1500px) {
  .select-input {
    width: 75%;
  }
  .m-label{
    margin-left: 80px;
  }
}

/* สำหรับหน้าจอขนาดใหญ่ */
@media (min-width: 1501px) {
  .select-input {
    width: 40%;
  }
  .m-label{
    margin-left: 1270px;
  }
}