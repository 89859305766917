
.container {
  padding: 16px;
  /* max-width: 400px; */
  height: auto!important;
  margin: auto;
}
.containers {
  padding: 16px;
  max-width: 1067px;
  height: auto !important;
  margin: auto !important;
}
.paper {
  padding: 16px!important;
  margin-bottom: 16px!important;
  border: 1px solid rgba(248, 135, 115, 0.5)!important; /* สีขอบโปร่งใส 50% */
  box-shadow: 0 4px 8px rgba(255, 94, 67, 0.3)!important;
  border-radius: 12px!important;
}

.button-container {
  text-align: center!important;
  margin-top: 20px!important;
}

.button {
  background-color: #FF6F61!important;
}

.button:hover {
  background-color: #e65b50!important;
}






.button {
  background-color: #E0E0E0!important; /* สีพื้นหลังของปุ่ม */
  color: black!important; /* สีของข้อความ */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)!important; /* เงาของปุ่ม */
  border-radius: 8px!important; /* ขอบโค้งของปุ่ม */
  padding: 8px 16px!important;
  width: 300px;
}

.button:hover {
  background-color: #D5D5D5!important; /* สีพื้นหลังของปุ่มเมื่อ hover */
}

.label {
  font-family: "Prompt-Thin" !important;
  /* font-size: "10px" !important; */
}

.value {
  font-weight: bold!important;
  /* font-size: 1rem!important; */
  font-family: "Prompt-Thin" !important;
}

.body2 {
  font-size: 0.875rem!important;
  font-family: "Prompt-Thin" !important;
}

.button {
  font-size: 1rem!important;
  font-family: "Prompt-Thin" !important;
}


.small .label,
.small .value,
.small .body2,
.small .button {
  font-size: 0.875rem !important;
  /* font-family: "Prompt-Thin" !important; */
}

.payment-options {
  margin-top: 20px;
  /* text-align: center; */
  background-color: #f9f9f9; /* เพิ่มพื้นหลังสีอ่อน */
  box-shadow: 0 4px 8px rgba(174, 174, 174, 0.3)!important;
  padding: 16px;
  border-radius: 8px; /* เพิ่มมุมโค้ง */
}

.payment-options-new {
  margin-top: 20px;
  text-align: center;
  background-color: #f9f9f9; /* เพิ่มพื้นหลังสีอ่อน */
  box-shadow: 0 4px 8px rgba(174, 174, 174, 0.3)!important;
  padding: 16px;
  border-radius: 8px; /* เพิ่มมุมโค้ง */
}

.payment-options h6 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.payment-option-container {
  display: flex;
  justify-content: space-around; /* กระจายไอคอนให้ห่างกัน */
  align-items: center;
}

.payment-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f44336;
  cursor: pointer;
}

.payment-option svg {
  font-size: 2rem;
}

.payment-option p {
  margin-top: 8px;
  font-size: 1rem;
}

.payment-notice {
  font-size: 0.675rem !important;
  font-family: "Prompt-Thin" !important;
  color: #f44336;
  margin-top: 16px;
  text-align: left;
}

.set-font {
  font-family: "Prompt-Thin" !important;
}
.btn-checkout-back {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 100%;
  margin-top: 25px;
}