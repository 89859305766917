button.active {
  background: rgba(255, 94, 67, 0.3);
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: -35px;
}

.layoutService {
  cursor: pointer;
  gap: 0.125rem;
  justify-content: flex-start !important; 
  /* gap: 16px; */
}

.frame-policy {
  cursor: pointer;
  border: solid 2px #fdcfc7 !important;
  background-color: #ffff !important;
  border-radius: 16px !important;
}

.frame-policy-new {
  /* cursor: pointer; */
  border: solid 2px #fdcfc7 !important;
  background-color: #ffff !important;
  border-radius: 16px !important;
}

.frame-policy:hover {
  border: solid 2px #fdcfc7 !important;
  background-color: #fdcfc7 !important;
  border-radius: 16px !important;
}

.card-service {
  box-shadow: 0px 2px 1px 0px rgba(187, 186, 185, 0.25);
  background: rgba(255, 228, 224, 1);
  border-radius: 16px;
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28%;
  height: 70px;
  margin: 0 0.5rem;
}
.fontSizeCard {
  font-size: 10px;
  margin: 0;
}
.img-menu-serivce {
  width: 23px;
  height: 23px;
}
.card-service:hover {
  background: rgb(253, 174, 161);
}

.card-service-disble {
  box-shadow: 0px 2px 1px 0px rgba(187, 186, 185, 0.25);
  background-color: rgba(246, 246, 246, 1);
  border-radius: 16px;
  margin: 5px;
  width: 25%;
}

/* การชำระเบี้ย */
.card-premium {
  background-color: rgba(246, 246, 246, 1);
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 14px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.card-premium-font-size-lable {
  font-size: 15px;
}

.btn-checkout-premium {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(255, 94, 67, 1);
  border-radius: 16px;
  border: 0;
  color: white;
  width: 100%;
  margin-top: 25px;
}

.btn-checkout-back {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 100%;
  margin-top: 25px;
  font-size: 15px;
}

.skeleton-loading {
  display: flex;
  width: 100%;
}

/* หน้าจอใหญ่ */

.carousel .control-dots .dot {
  border-radius: 25px;
  border: 0;
  background: rgba(68, 46, 46, 0.11);
  width: 15px;
  height: 15px;
  z-index: 1;
}

.carousel .control-dots {
  position: absolute !important;
  margin: 10px 0 !important;
  padding: 0 !important;
  text-align: center !important;
  width: 100% !important;
  z-index: 1 !important;
}

/* .carousel .control-dots .dot:active {
  border-radius: 25px;
  border: 0;
  background: rgba(255, 94, 67, 0.3) !important;
  width: 15px;
  height: 15px;
} */

/* .carousel-status {
  text-shadow: none !important;
  font-size: 15px !important;
  color: #FF5E43 !important;
} */

.carousel .slide {
  text-align: start;
}

@media only screen and (max-width: 600px) {
  .slide {
    /* min-width: 50% !important; */
  }
}

/* For all other screen sizes, display 3 items */
.slide {
  /* min-width: 50% !important; */
}
.font-size-label {
  font-size: 15px;
  color: #606060;
  margin-left: 15px;
}

@media (max-width: 575.98px) {
  .font-size-label {
    font-size: 15px;
    color: #606060;
    font-size: 13px;
  }
}

.Modal {
  position: absolute;
  top: 35%;
  left: 50%;
  display: block;
  padding: 1em;
  min-width: 23em;
  max-width: 70%;
  background-color: #fff4f4;
  transition: 5ms;
  border-radius: 1em;
  transform: translate(-50%, -50%);
  outline: transparent;
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
}

@media (min-width: 1000px) {
  .Modal {
    position: absolute;
    top: 30%;
    left: 50%;
    display: block;
    padding: 2em;
    min-width: 30em;
    max-width: 70%;
    background-color: #fff4f4;
    transition: 5ms;
    border-radius: 1em;
    transform: translate(-50%, -50%);
    outline: transparent;
  }
}

.input-modal-search  {
  border-radius: 6px !important;
  border-color: #FB5E3F !important;
}

.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(0,0,0,.4); */
}
.card-loading {
  border-radius: 16px;
  justify-content: start;
  width: 80%;
  height: 70px;
}
.card-premium-loading {
  background-color: rgba(246, 246, 246, 1);
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 14px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.img-modal {
  position: fixed !important;
  z-index: -10 !important;
  top: 1 !important;
  bottom: 1px !important;
  left: 0 !important;
  right: 0 !important;
  max-width: 100%;
  height: auto;
  display: block;
}
/* .font-size-label {
  font-weight: normal;
  color: #666;
}
.bold {
  font-weight: bold;
  color: #000;
} */
@media (max-width: 600px) {
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .font-size-label {
    font-size: 13px;
    color: #606060;
    margin-left: 1px; 
  }
}

@media (min-width: 601px) {
  .desktop-view {
    display: block;
  }
  .mobile-view {
    display: none;
  }
  .card-service {
    box-shadow: 0px 2px 1px 0px rgba(187, 186, 185, 0.25);
    background: rgba(255, 228, 224, 1);
    border-radius: 16px;
    /* padding: 5px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 8%;
    height: 70px;
    margin: 0 0.5rem;
    max-width: 200px;
  }
  .layoutService {
    cursor: pointer;
    gap: 0.125rem;
    justify-content: flex-start !important; 
    gap: 16px;
  }
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.label {
  font-weight: normal;
  color: #666;
}

.value {
  text-align: right;
  color: #000;
}

.bold {
  font-weight: bold;
}

.MuiGrid-item {
  padding-left: 16px !important;
  padding-right: 10px !important;
}

.containers {
  padding: 16px;
  max-width: 1067px;
  height: auto !important;
  margin: auto !important;
  justify-content: start !important;
}

.container-other  {
  padding: 16px;
  max-width: 600px !important;
  margin: auto;
  justify-content: start !important;
}


