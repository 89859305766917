.bodyListGroup {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;

}
.btnBack{
  background-color: #F2F2F2 !important;
  border-radius: 16px !important;
  padding: 10px 25px !important;
  color: #2F2D2C !important;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 30px;
  width: 200px;
}
.btnBack:hover{
  background-color: #a09e9e !important;
  border: #BBBAB9 !important;
  padding: 10px 25px !important;
  color: #000000 !important;
}


.frameCard {
  border: solid 2px #fdcfc7 !important;
  border-radius: 0 0 16px 16px !important; /* มุมด้านล่างเท่านั้นที่โค้ง */ 
}
.frameCard2 {
  border: solid 2px #fdcfc7 !important;
  border-radius: 16px !important;  /* มุมทุกด้านโค้ง */
}
.margin1 {
  margin: 1rem;
}

@media (min-width: 1000px) {
  
  .pay-btn, .btn-back {
      width: 30% !important; /* ปรับความกว้างเป็น 40% เมื่อหน้าจอใหญ่ */
  }
  .margin-15 {
      margin-top: 2rem;
      margin-right: 10rem;
      margin-left: 10rem;
  }
  ul{
      padding-right: 0px !important;
     
  }
  #fill-tab-example{
      margin-bottom: 0px!important;
  }
  #fill-tab-example-tab-home{
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
  }
  #fill-tab-example-tab-profile{
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
  }
}
.card-custom {
  border-bottom: 1px solid #f4c6be;
  border-left: 1px solid #f4c6be;
  border-right: 1px solid #f4c6be;
  border-radius: 0 0 5px 5px; /* โค้งเฉพาะมุมล่าง */
  padding: 10px; /* เพิ่ม padding ถ้าต้องการ */
  box-shadow: 0 1px 2px #efc4bd
}
.card-custom2 {
  border-bottom: 1px solid #f4c6be;
  border-left: 1px solid #f4c6be;
  border-right: 1px solid #f4c6be;
  border-radius: 16px; /* โค้งทุกมุม */
  
  padding: 10px; /* เพิ่ม padding ถ้าต้องการ */
  box-shadow: 0 1px 2px #efc4bd
}
.customDots:focus {
  border-radius: 25px;
  border: 0;
  background: rgba(255, 94, 67, 0.3) !important;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}


.customDots {
  border-radius: 25px;
  border: 0;
  background: rgba(68, 46, 46, 0.11);
  width: 15px;
  height: 15px;
  margin-left: 5px;
  z-index: 10;
}