body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-border-width: 0;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

/* @font-face {
  font-family: "DB AdmanRounded X";
  src: local("DB AdmanRounded X"),
   url("./fonts/DB\ AdmanRounded/DB AdmanRounded X.ttf") format("truetype");
  font-weight: normal;
} */

@font-face {
  font-family: "Prompt-Thin";
  src: local("Prompt-Thin"),
   url("./fonts/Prompt/Prompt-Medium.ttf") format("truetype");
  font-weight: normal;
}
