.edit-phone {
  button.active {
    background: rgba(255, 94, 67, 0.3);
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -35px;
  }

  .footer-space {
    padding-bottom: 72px;
  }

  .address-policy-card {
    cursor: pointer;
    border: solid 2px #fdcfc7 !important;
    background-color: #ffff !important;
    border-radius: 16px !important;
    padding: 16px;
    padding-bottom: 44px;
    /* min-height: 278px; */
  }

  .address-policy-card:hover {
    border: solid 2px #fdcfc7 !important;
    background-color: #fdcfc7 !important;
    border-radius: 16px !important;
  }

  .address-policy-card.selected {
    border: solid 4px #ff5e43 !important;
  }

  .address-card-title {
    font-weight: 700;
    font-size: 18px;
    padding: 4px;
  }
  .phone-card-body {
    padding: 4px;
    padding-bottom: 8px;
    font-size: 15px;
  }

  .address-image-card {
    border:solid 2px #fdcfc7;
    background-color: #ffff !important;
    border-radius: 16px !important;
    padding: 16px;
  }

  .line {
    margin: 0px 0px 15px 0px;
    border: solid 1px;
  }

  .address-icon {
    width: 20px;
    margin: 0px 16px 0px 0px;
  }

  .btn-address-next {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: #ff5e43;
    border-radius: 16px;
    border: 0;
    color: white;
    width: 100%;
  }

  .btn-address-back {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: rgba(242, 242, 242, 1);
    border-radius: 16px;
    border: 0;
    width: 100%;
  }

  .update-receiving-address {
    border:solid 2px #fdcfc7;
    border-radius: 15px;
    padding: 16px;
    margin-top: 24px;
    background-color: #ffff;
  }

  .action-address {
    /* margin-top: 24px; */
    align-items: center;   /* จัดตำแหน่งตรงกลางแนวตั้ง */
    justify-content: center; /* จัดตำแหน่งตรงกลางแนวนอน */
    /* min-height: 100vh;  */
  }
}
.container-footer-space {
  max-width: 1067px;
  padding: 16px;
  margin: auto;
  margin-bottom: 100px;
}

.css-1mle9ic-MuiGrid-root>.MuiGrid-item{
padding: 10px!important;
}

.font-size-label-pol{
  font-family: "Prompt-Thin", sans-serif !important;
  font-size: 12px !important;
  color:black !important;
  margin-left: 15px !important;
}
.font-size-content-pol {
  font-family: "Prompt-Thin", sans-serif !important;
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 20px;
}
.css-un48xx-MuiGrid-root>.MuiGrid-item{
  padding-left: 0px!important;
}