.mail-management-history {
  .mail-page-container {
    max-width: 829px;
    padding: 16px;
    margin: auto;
  }
  .rounded-border {
    border-radius: 14px;
    border: 1px solid #e1dfdfa8;
  }
  .input-section {
    margin: auto;
    margin-top: 24px;
    max-width: 600px;
  }
  .info-section {
    margin: auto;
    margin-top: 24px;
  }
  .action-btn-section {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .btn-fill-orange {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: #ff5e43;
    border-radius: 16px;
    border: 0;
    min-width: 80px;
    color: white;
  }

  .footer-space {
    padding-bottom: 72px;
  }
}
