.edit-address {
  button.active {
    background: rgba(255, 94, 67, 0.3);
  }

  .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: -35px;
  }

  .footer-space {
    padding-bottom: 72px;
  }

  .address-policy-card {
    cursor: pointer;
    border: solid 2px #fdcfc7 !important;
    background-color: #ffff !important;
    border-radius: 16px !important;
    padding: 16px;
    padding-bottom: 44px;
    /* min-height: 278px; */
  }

  .address-policy-card:hover {
    border: solid 2px #fdcfc7 !important;
    background-color: #fdcfc7 !important;
    border-radius: 16px !important;
  }

  .address-policy-card.selected {
    border: solid 4px #ff5e43 !important;
  }

  .address-card-title {
    font-weight: 700;
    font-size: 18px;
    padding: 4px;
  }

  .address-card-body {
    padding: 4px;
    font-size: 15px;
  }

  .address-image-card {
    border:solid 2px #fdcfc7;
    background-color: #ffff !important;
    border-radius: 16px !important;
    padding: 16px;
  }

  .line {
    margin: 0px 0px 15px 0px;
    border: solid 1px;
  }

  .address-icon {
    width: 20px;
    margin: 0px 16px 0px 0px;
  }

  .btn-address-next {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: #ff5e43;
    border-radius: 16px;
    border: 0;
    color: white;
    width: 100%;
  }

  .btn-address-back {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: rgba(242, 242, 242, 1);
    border-radius: 16px;
    border: 0;
    width: 100%;
  }

  .update-receiving-address {
    border:solid 2px #fdcfc7;
    border-radius: 15px;
    padding: 16px;
    margin-top: 24px;
    background-color: #ffff;
  }

  .action-address {
    /* margin-top: 24px;
    align-content: center; */
    /* display: flex; */
    align-items: center;   /* จัดตำแหน่งตรงกลางแนวตั้ง */
    justify-content: center; /* จัดตำแหน่งตรงกลางแนวนอน */
    /* min-height: 100vh;  */
  }
}
.font-size-label-pol{
  font-family: "Prompt-Thin", sans-serif !important;
  font-size: 15px;
  color:black;
  margin-left: 15px;
  width: 50px!important;
}
.font-size-title {
  font-size: 15px;
  color: #606060;
  margin-left: 15px;
  font-weight: bold;
}

.font-size-content-pol {
  font-family: "Prompt-Thin", sans-serif !important;
  font-size: 12px!important;
  color: black;
  font-weight: bold;
  margin-top: 10px;
  /* margin-left: 20px; */
}

.body-list-group {
  padding: 5px;

}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1; 
}

.Modal {
  position: absolute;
  top: 35%;
  left: 50%;
  display: block;
  padding: 1em;
  min-width: 23em;
  max-width: 40%;
  background-color: #ffffff;
  transition: 5ms;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  outline: transparent;
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  z-index: 2; 
}



.modal-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  z-index: 3; 
}

.font-modal{
  margin-top: 20px;
  text-align: center;
}

.modal-image-size{
  width: 150px;
  height: 150px;
}

.btn-checkout-modal {
  box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
  background-color: rgba(242, 242, 242, 1);
  border-radius: 16px;
  border: 0;
  width: 50%;
  margin-top: 25px;
}
@media (max-width: 420px){
  .modal-alert{
    width: auto;
    height: auto;
  }

  .font-modal{
    margin-top: 1px;
    text-align: center;
    font-size: 12px;
  }

  .modal-image-size{
    width: 80px;
    height: 80px;
  }

  .btn-checkout-modal {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: rgba(242, 242, 242, 1);
    border-radius: 16px;
    border: 0;
    width: 50%;
    margin-top: 10px;
  }
}

.container-footer-space {
    max-width: 1067px;
    padding: 16px;
    margin: auto;
    margin-bottom: 100px;
}

.css-1mle9ic-MuiGrid-root>.MuiGrid-item{
padding: 10px!important;
}


