.benefit-receiving-detail {
  .table-page-container {
    max-width: 1067px;
    padding: 16px;
    margin: auto;
  }
  .rounded-border {
    border-radius: 14px;
    border: 1px solid #e1dfdfa8;
  }
  .title-section {
    margin-top: 24px;
  }
  .input-section {
    margin-top: 16px;
    margin-inline: 16px;
  }
  .action-btn-section {
    margin-block: 20px;
    display: flex;
    justify-content: center;
  }

  .btn-fill-gray {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: rgba(242, 242, 242, 1);
    border-radius: 16px;
    border: 0;
    min-width: 80px;
  }

  .btn-fill-orange {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: #ff5e43;
    border-radius: 16px;
    border: 0;
    min-width: 80px;
    color: white;
  }

  .btn-bordered-orange {
    box-shadow: 0px 5px 1px 0px rgba(187, 186, 185, 0.5);
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    border: 1px solid #ff5e43a8;
    color: #ff5e43;
  }

  .footer-space {
    padding-bottom: 72px;
  }
}
