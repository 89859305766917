.add_para_button{
    margin: 0.5rem;
    background-color: white;
    color: green;
    border: 2px solid green;
    padding:0.25rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
    border-radius: 5px;
    font: 0.9rem sans-serif;
    font-weight: 400;
}
.add_para_button:hover{
    background-color: rgb(232, 232, 232);
}
.parameter_button_group{
    display:flex;

}
.add_button{
    display: flex;

}
.back_button{
margin-left: auto;
}
.head_parameter{
    color: coral;
    font-weight: bold;
}
.actionBlock{
    margin: 0.5rem;
    background-color: green;
    color: white;
    padding:0.5rem;
    padding-left:0.75rem;
    padding-right: 0.75rem;
    border-radius: 25px;
}
.inActionBlock{
    margin: 0.5rem;
    background-color: red;
    color: white;
    padding:0.5rem;
    padding-left:0.75rem;
    padding-right: 0.75rem;
    border-radius: 25px;
}
.detail_action{

margin: auto;
}
.detail_button{
    font-weight: 200;
    font: 0.95rem sans-serif;
    color: #10CAF0;
    background-color: white;
    border: 2px solid #10CAF0;   
    border-radius: 5px;
}
.detail_button:hover{
    color: rgb(152, 190, 203);
    background-color: rgb(232, 232, 232);
    border: 2px solid rgb(152, 190, 203);   
    border-radius: 5px;
}
.popButton{
    display: flex;
    margin: auto;
    flex: auto;
    justify-content:flex-start;
}
.popButton_in{
    display: flex;
    justify-content:end;
    flex: 1;
    margin: auto;
}
.popButton_in_cancel{
    display: flex;
    justify-content:end;
    flex: 1;
    margin:1%;
}
.popButton_in_button{
    margin: auto;
    font-size: 1.25rem;
    color: white;
    width: 90%;
    height: 100%;
    background-color: coral;
}
.popButton_in_button_save{
    margin-left: 50%;
    width: 50%;
}
.popButton_in_button_cancel{
    margin-right: 50%;
    width: 50%;
}
.popButton_in_button:hover{
    background-color: rgb(235, 117, 74);
}
.popButton_in_button:active{
    background-color: rgb(235, 117, 74);
}
.MuiDataGrid-columnHeaderTitleContainer{
    background-color: rgb(189,229,254);
}
.MuiSwitch-thumb.clicked{
    color: coral;
}
.MuiDataGrid-iconSeparator path{
    color: white;
}